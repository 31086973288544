import { productTypes } from "../../store/constants"

export default {
  doEmergencyProductFilter: function(siteContent) {
    let emergencyProductFilterList =
      Object.values(siteContent.SiteConfig)[0].forceHideProducts || ""

    emergencyProductFilterList = emergencyProductFilterList
      .replace(/\s/g, "")
      .split(",")

    //
    ;[productTypes.PRODUCT, productTypes.SET].forEach(contentType => {
      const _arr = Object.entries(siteContent[contentType])
      _arr.forEach(entry => {
        if (emergencyProductFilterList.includes(entry[1].sku)) {
          delete siteContent[contentType][entry[0]]
        }
      })
    })
  }
}
