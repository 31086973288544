import { Bleach } from "@/main.js"

import { productTypes } from "../../store/constants"

export default {
  mergeContent: function(allContent) {
    // Merge the results of the two separate API calls
    const Contentful_response = JSON.parse(JSON.stringify(allContent[0]))
    const BleachAPI_response = JSON.parse(JSON.stringify(allContent[1]))

    let mergedContent_all = {}

    // For each product type in the Contentful product response
    Object.entries(Contentful_response).forEach(contentType => {
      // Take each collection of products

      const contentType_id = contentType[0]
      const contentType_entries = contentType[1]

      let mergedContent_byType = {}

      Object.entries(contentType_entries).forEach(entry => {
        // For each product, merge the object data with the corresponding product object coming from BleachAPI

        const entry_id = entry[0]
        const entry_data = entry[1]

        // If there's a corresponding product coming from BleachAPI, merge 'em
        if (Object.values(productTypes).includes(contentType_id)) {
          if (
            BleachAPI_response[contentType_id] &&
            BleachAPI_response[contentType_id][entry_id] &&
            // If showOnStorefront is not set (backwards compatibility)
            (entry_data.showOnStorefront == true ||
              entry_data.showOnStorefront == null)
          ) {
            mergedContent_byType[entry_id] = Object.assign(
              entry_data,
              BleachAPI_response[contentType_id][entry_id]
            )
          } else {
            if (entry_data.activeThisStore) {
              Bleach.debug.warn(
                `Product data mismatch: ${entry_data.__typename} ${entry_id} ${entry_data.name}`,
                entry_data
              )
            }
          }
        } else {
          mergedContent_byType[entry_id] = entry_data
        }
      })

      // Assign the merged product to the 'output' collection
      mergedContent_all[contentType_id] = mergedContent_byType
    })

    return mergedContent_all
  }
}
