import BleachAPI from "../integration/BleachAPI"
// import ContentCache from "@/integration/ContentCache"

import ContentMerge from "@/integration/ContentfulAPI/ContentMerge.js"
import ProductFilter from "@/integration/ContentfulAPI/ProductFilter.js"

import { productTypes } from "../store/constants"

import ContentfulGQL from "./ContentfulGQL"

let productData = {}

export default {
  getContent: async function(locale, store) {
    return Promise.all([
      fetchCoreContent(locale),
      fetchProductsFromBleachAPI(store, locale)
    ])
      .then(allContent => {
        // Merge the results of the two separate API calls
        console.warn("allContent", allContent)
        // Merge the products according to the new protocol ()
        return ContentMerge.mergeContent(allContent)
        // return Object.assign({}, ...JSON.parse(JSON.stringify(allContent)))
      })
      .then(mergedContent => {
        console.warn("mergedContent", mergedContent)
        // TEMPORARY (hopefully) HACK TO FILTER OUT UNWANTED PRODUCTS
        ProductFilter.doEmergencyProductFilter(mergedContent)

        return mergedContent
      })
  }
}

async function fetchProductsFromBleachAPI(store, locale) {
  if (Object.keys(productData).length) {
    return productData
  } else {
    return BleachAPI.getProducts(store, locale).then(data => {
      productData = {
        ...mapProductTypes(data.products, "Product"),
        ...mapProductTypes(data.giftCards, "ProductGiftCard"),
        ...mapProductTypes(data.bundles, "ProductBundle")
      }
      console.log(JSON.parse(JSON.stringify(productData)))
      return JSON.parse(JSON.stringify(productData))
    })
  }
}

function fetchCoreContent(locale) {
  let contentfulSplitRequest = []

  // TODO: Just requesting siteConfig separately for testing, make this better!
  contentfulSplitRequest.push(
    ContentfulGQL.fetchFirstOfContentType("SiteConfig", locale).then(
      response => {
        return mapContentfulGraphQL(
          "SiteConfig",
          response.data[`siteConfigCollection`].items
        )
      }
    )
  )

  // Pre-load all products as 'core' content (mostly just to avoid painful async merging logic)
  // TODO - might this be done async in future?
  Object.values(productTypes).forEach(type => {
    contentfulSplitRequest.push(
      ContentfulGQL.fetchAllOfContentType(type, locale, 300).then(items => {
        return mapContentfulGraphQL(type, items)
      })
    )
  })

  return Promise.all(contentfulSplitRequest).then(responses => {
    let combinedResponses = {}
    responses.forEach(responseItems => {
      combinedResponses = {
        ...combinedResponses,
        ...responseItems
      }
    })
    return combinedResponses
  })
}

function mapProductTypes(items, contentType) {
  let mappedContent = {}
  mappedContent[contentType] = {}

  items.forEach(product => {
    mappedContent[contentType][product.cmsId] = {
      ...product,
      // Bleach API returns an 'id' value which is different from Contentful's 'id', and uses 'cmsId' for that value instead
      // So we must map it back to match Contentful.
      id: product.cmsId,
      bleachAPI_id: product.id,
      __typename: contentType
    }
  })

  return mappedContent
}

function mapContentfulGraphQL(contentType, items) {
  let mappedContent = {}

  // CREATE A HASHMAP OF CONTENT TYPE TO ENTRIES
  items.forEach(entry => {
    const contentModelType = contentType

    if (mappedContent[contentModelType] == undefined) {
      mappedContent[contentModelType] = {}
    }

    mappedContent[contentModelType][entry.sys.id] = {
      ...entry,
      id: entry.sys.id,
      contentType: contentModelType,
      sys: entry.sys
    }
  })
  return mappedContent
}
